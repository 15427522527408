import { render, staticRenderFns } from "./MultipleCurrencyTotalPrompt.vue?vue&type=template&id=72c091e2&scoped=true"
import script from "./MultipleCurrencyTotalPrompt.vue?vue&type=script&lang=js"
export * from "./MultipleCurrencyTotalPrompt.vue?vue&type=script&lang=js"
import style0 from "./MultipleCurrencyTotalPrompt.vue?vue&type=style&index=0&id=72c091e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c091e2",
  null
  
)

export default component.exports