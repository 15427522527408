<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-table ref="multipleTable" :data="transactions" style="width: 100%">
          <el-table-column :label="$t('column.date')" property="displayDate" />
          <el-table-column :label="$t('column.file')">
            <template slot-scope="scope">
              <a v-if="scope.row.file" :href="scope.row.file">
                <i class="fas fa-file-upload action-icon" />
              </a>
              <el-tooltip
                v-if="isRoleAdmin()"
                :content="$t('transaction.form.reUploadFile')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span @click="openTransactionPanel(scope.row)">
                  <i class="fas fa-edit action-icon action-icon" />
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.amount')">
            <template slot-scope="scope">
              {{
                addCurrencySymbol(
                  scope.row.price,
                  scope.row.currency
                )
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.remark')" property="remark" />
          <el-table-column
            v-if="displayValidColumn && isRoleAdmin()"
            :label="$t('column.action')"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.is_valid === 0">
                <el-button
                  type="primary"
                  size="mini"
                  @click="
                    () => {
                      checkValidWithMark(scope.row.id);
                    }
                  "
                >
                  {{ $t("transaction.form.confirmTheTransaction") }}
                </el-button>
              </div>
              <div v-else>
                <el-tooltip
                  v-if="userWhoConfirmedTransaction"
                  class="item"
                  :content="userWhoConfirmedTransaction.name"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span style="color: #42a16a">
                    {{ $t("transaction.form.confirmed") }}
                  </span>
                </el-tooltip>
                <span v-else>
                  {{ $t("transaction.form.confirmed") }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <TransactionForm
      v-if="editTransactionMode"
      :submitButtonText="$t('button.save')"
      :isOnlyUploadFileMode="true"
      :price="transaction.price"
      :currency="transaction.currency"
      :remark="transaction.remark"
      :date="transaction.displayDate"
      @handleOk="updateTransaction"
    />
  </div>
</template>

<script>
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import TransactionForm from "@/components/bill/TransactionForm";
import transactionApi from "@/apis/transaction";

export default {
  components: {
    TransactionForm
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    userWhoConfirmedTransaction: {
      type: Object,
      default: null
    },
    transactions: {
      type: Array,
      default: () => []
    },
    displayValidColumn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      transaction: null,
      editTransactionMode: false
    };
  },
  mixins: [moneyMixin, roleMixin],
  methods: {
    checkValidWithMark(transactionId) {
      this.$emit("verifyTransaction", transactionId, true);
    },
    openTransactionPanel(transaction) {
      this.transaction = transaction;
      this.editTransactionMode = true;
    },
    async updateTransaction(newTransaction) {
      try {
        await transactionApi.updateTransactionFile(
          this.transaction.id,
          newTransaction.file
        );
        await this.$emit("loadTransactions");
        this.editTransactionMode = false;
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>
