<template>
  <div class="promptText">
    * 如果項目中有多個幣值，Total 會依照匯率換算成相同幣值。
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.promptText {
  font-size: 10px;
  color: red;
  text-align: right;
}
</style>
