<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="lastPage">
        {{ pageTitle }}
      </template>
    </Breadcrumb>
    <template v-if="isRoleAdmin()">
      <el-alert v-if="isProblem() || isChecked()" type="error">
        <template v-if="isProblem()" slot="title">
          <div>{{ $t("salary.detail.teacherHasProblem") }}</div>
          <span>{{ $t("salary.detail.theProblem") }}{{ problem }}</span>
        </template>
        <template v-else-if="isChecked()" slot="title">
          <span>{{ $t("salary.detail.pleasePay") }}</span>
        </template>
      </el-alert>
      <el-alert v-else-if="isPaid()" show-icon type="success">
        <template slot="title">
          <span>{{
            `${$t("salary.detail.describePayA")}${PaidAt}${$t(
              "salary.detail.describePayB"
            )}`
          }}</span>
        </template>
      </el-alert>
      <el-alert v-else show-icon type="warning">
        <template slot="title">
          <span v-if="salayIsTemp()">{{ $t("salary.detail.confirmPending") }}</span>
          <span v-else>暫存和未發送的薪資單</span>
        </template>
      </el-alert>
    </template>
    <template v-else-if="isRoleTeacher() && !salayIsTemp()">
      <el-alert v-if="isCreated()" type="error">
        <template slot="title">
          <span>{{ $t("salary.detail.pleaseReview") }}</span>
        </template>
      </el-alert>
      <el-alert v-else show-icon type="warning">
        <template v-if="isChecked()" slot="title">
          <span>{{ $t("salary.detail.alreadyConfirmed") }}</span>
        </template>
        <template v-if="isProblem()" slot="title">
          <span>{{ $t("salary.detail.recivedFeeback") }}</span>
        </template>
        <template v-if="isPaid()" slot="title">
          <span>{{ $t("salary.detail.alreadyReceived") }}</span>
        </template>
      </el-alert>
    </template>
    <h1 class="text-center mt-5 mb-3">
      <span>{{ pageTitle }}</span>
      <DeleteIconButton
        v-if="isPaid()"
        :onDelete="deleteTeacherPayroll($route.params.salaryId)"
      />
      <router-link
        v-if="isRoleAdmin() && !isPaid()"
        :to="
          `/salary/teacher/${this.$route.params.teacherId}/reset/${this.$route.params.salaryId}`
        "
      >
        <i class="fas fa-edit action-icon" />
      </router-link>
    </h1>
    <el-row
      v-if="isRoleAdmin()"
      class="rowsGap"
      :gutter="20"
      type="flex"
      align="middle"
    >
      <el-row class="rowGap">
        <el-col>
          <h2>
            <div v-if="editTitle" style="display: flex">
              {{ $t("salary.form.title") }}
              <el-input style="width: 500px" v-model="salaryTitle" />
              <el-button type="primary" @click="updateSalary">
                {{ $t("salary.form.save") }}
              </el-button>
              <el-button
                type="danger"
                @click="
                  () => {
                    editTitle = false;
                  }
                "
              >
                {{ $t("salary.form.cancel") }}
              </el-button>
            </div>
            <span v-else>
              <el-button
                type="primary"
                @click="
                  () => {
                    editTitle = true;
                  }
                "
              >
                {{ $t("salary.detail.editPayrollName") }}
              </el-button>
            </span>
          </h2>
        </el-col>
      </el-row>
    </el-row>
    <el-row class="rowsGap">
      <el-table :data="[teacherUnpaid]" style="width: 100%">
        <el-table-column
          :label="$t('column.teacher')"
          prop="user_name"
          width="200"
        />
        <el-table-column>
          <template slot="header">
            <div style="text-align: center">
              {{ $t("column.billingProgress") }}
            </div>
          </template>
          <template v-if="teacherUnpaid" slot-scope="scope">
            <StepOfAdmin
              v-if="isRoleAdmin()"
              :scope="scope"
              :isPaid="isPaid()"
            />
            <StepOfTeacher
              v-else-if="isRoleTeacher()"
              :scope="scope"
              :isPaid="isPaid()"
            />
          </template>
        </el-table-column>
      </el-table>
      <hr class="separate-line" />
    </el-row>
    <el-row class="rowsGap">
      <h2>{{ $t("salary.form.salaryStatement") }}</h2>
      <el-table
        :data="salaryItems"
        style="width: 100%"
        :summary-method="getSalaryItemSummaries"
        show-summary
      >
        <el-table-column :label="$t('column.item')" prop="statement" />
        <el-table-column :label="$t('column.salary')" prop="salary">
          <template slot-scope="scope">
            <div>
              {{
                addCurrencySymbol(
                  convertPriceByCurrency(
                    scope.row.salary,
                    scope.row.currency,
                    salaryMainCurrency
                  ),
                  salaryMainCurrency
                )
              }}
            </div>
            <div
              v-if="scope.row.currency !== salaryMainCurrency"
              class="originalPrice"
            >
              ≈{{ addCurrencySymbol(scope.row.salary, scope.row.currency) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isRoleAdmin()"
          width="100"
          :label="$t('column.action')"
        >
          <template>
            <span
              @click="
                () => {
                  $message.warning(
                    this.$t('如果要修改金額請刪除此薪資單後重新建立！')
                  );
                }
              "
            >
              <i class="fas fa-edit action-icon" />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <MultipleCurrencyTotalPrompt v-if="isSalaryItemCurrencyMultiple" />
    </el-row>
    <el-row v-if="remark">
      <el-col :span="24">
        <b>{{ $t("salary.detail.note") }}</b>
        {{ remark }}
      </el-col>
    </el-row>
    <hr class="separate-line" />
    <el-row class="rowsGap">
      <h2>Lesson Logs</h2>
      <el-table
        v-if="teachingLogs"
        :data="teachingLogs"
        style="width: 100%"
        :summary-method="getLesssonLogSummaries"
        show-summary
      >
        <el-table-column :label="$t('column.date')">
          <template slot-scope="scope">
            {{ instant.formatDate(scope.row.started_at) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column.teacher')"
          prop="teacher_user_name"
        />
        <el-table-column :label="$t('column.student')">
          <template slot-scope="scope">
            <span
              :key="lessonLog.id"
              v-for="(lessonLog, index) in scope.row.lesson_logs"
            >
              {{ lessonLog.student_user.name
              }}<span v-if="index !== scope.row.lesson_logs.length - 1">,</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.courses')">
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.lesson_logs.length > 0"
              :to="
                `/private/private-lessons/${scope.row.session_class_id}/log/${scope.row.lesson_logs[0].id}/student/${scope.row.lesson_logs[0].student_user_id}`
              "
            >
              {{ scope.row.session_class_subject }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.hour')" prop="full_hour" />
        <el-table-column :label="$t('column.salary')">
          <template slot-scope="scope">
            <div>
              {{
                addCurrencySymbol(
                  convertPriceByCurrency(
                    Number(scope.row.salary) * scope.row.full_hour,
                    scope.row.salary_currency,
                    teachingLogMainCurrency
                  ),
                  teachingLogMainCurrency
                )
              }}
            </div>
            <div
              v-if="scope.row.salary_currency !== teachingLogMainCurrency"
              class="originalPrice"
            >
              ≈{{
                addCurrencySymbol(
                  Number(scope.row.salary) * scope.row.full_hour,
                  scope.row.salary_currency
                )
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <MultipleCurrencyTotalPrompt v-if="isTeachingLogCurrencyMultiple" />
    </el-row>
    <hr class="separate-line" />
    <el-row class="rowsGap">
      <h2>{{ $t("salary.form.paymentType") }}</h2>
      <el-table ref="multipleTable" :data="paymentMethods" style="width: 100%">
        <el-table-column
          :label="$t('salary.form.paymentType')"
          prop="title"
          width="200"
        />
        <el-table-column :label="$t('column.details')">
          <template slot-scope="scope">
            <span :key="index" v-for="(payment, index) in scope.row.payment">
              {{ payment[Object.keys(payment)[0]]
              }}<span v-if="scope.row.payment.length - 1 !== index">,</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div v-if="!salayIsTemp()">
      <div v-if="isRoleAdmin()">
        <h2>薪資單寄送紀錄</h2>
        <el-table :data="emailToUsers">
          <el-table-column label="寄送人">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.created_user"
                :to="{
                  name: 'ProfileForAdmin',
                  params: { id: scope.row.created_user.id }
                }"
              >
                {{ scope.row.created_user.name }}
              </router-link>
              <div v-else>
                未紀錄寄送人
              </div>
            </template>
          </el-table-column>
          <el-table-column label="日期">
            <template slot-scope="scope">
              {{ instant.formatDateTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column label="收件者">
            <template slot-scope="scope">
              <div
                :key="emailUser.email"
                v-for="(emailUser) in scope.row.emailUsers"
              >
                <span v-if="emailUser.id">
                  {{ emailUser.email }}
                  (<router-link
                    v-if="emailUser.id"
                    :to="{
                      name: 'ProfileForAdmin',
                      params: { id: emailUser.id }
                    }"
                  >
                    {{ userMathods.displayName(emailUser.first_name, emailUser.last_name) }}
                  </router-link>)
                </span>
                <span v-else>
                  {{ `${emailUser.email} (未知使用者)` }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="sendMail">
          重寄帳單
        </el-button>
      </div>
      <el-row v-if="isPaid()" class="rowsGap">
        <hr class="separate-line" />
        <Transactions
          :transactions="transactions"
          :displayValidColumn="false"
          @loadTransactions="fetchPayroll"
        />
      </el-row>
      <el-row v-if="isRoleAdmin() && isChecked()" class="rowsGap">
        <hr class="separate-line" />
        <h2>{{ $t("salary.detail.paymentInformation") }}</h2>
        <TransactionForm
          @handleOk="createSalaryTransaction"
          :price="totalPrice"
          :currency="salaryCurrency"
          :notePlaceholder="$t('salary.form.bothTheAdminAndTheInstructorCanSee')"
        />
      </el-row>
      <el-row
        v-if="isRoleAdmin() && isCreated() && !salayIsTemp()"
        class="rowsGap"
        type="flex"
        justify="center"
      >
        <el-col :span="24" style="text-align: center">
          <el-button type="primary" @click="teacherSubmitReponse">
            {{ $t("salary.detail.adminConfirmation") }}
          </el-button>
        </el-col>
      </el-row>
      <el-row
        v-if="isRoleTeacher() && isCreated() && !salayIsTemp()"
        class="rowsGap"
      >
        <h3 class="text-center mt-5 mb-3">
          {{ $t("salary.detail.isTheInformtaionCorrect") }}
        </h3>
        <div class="text-center">
          <el-radio-group v-model="isSomethingWrong">
            <el-radio :style="{ marginRight: '300px' }" :label="false">
              {{ $t("salary.detail.yesPayMe") }}
            </el-radio>
            <el-radio :label="true">{{
              $t("salary.detail.noContainError")
            }}</el-radio>
          </el-radio-group>
        </div>
        <div :style="{ height: problemBlockHeight }" class="rowsGap problem">
          <h2>{{ $t("salary.detail.whatSWrong") }}</h2>
          <el-input v-model="problem" type="textarea" rows="5" />
        </div>
        <div class="text-center">
          <el-button type="primary" @click="teacherSubmitReponse">
            {{ $t("button.submit") }}
          </el-button>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { user, instant, money } from "@ivy-way/material";
import MultipleCurrencyTotalPrompt from "@/components/prompt/MultipleCurrencyTotalPrompt";
import Breadcrumb from "@/components/Breadcrumb";
import StepOfAdmin from "@/views/salary/StepOfAdmin";
import StepOfTeacher from "@/views/salary/StepOfTeacher";
import TransactionForm from "@/components/bill/TransactionForm";
import Transactions from "@/components/bill/Transactions";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import salaryApi from "@/apis/salary";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import { EnumSalaryStatuses } from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    MultipleCurrencyTotalPrompt,
    Breadcrumb,
    StepOfAdmin,
    StepOfTeacher,
    TransactionForm,
    Transactions,
    DeleteIconButton
  },
  mixins: [moneyMixin, roleMixin],
  data() {
    return {
      editTitle: false,
      status: 100,
      salaryTitle: "",
      totalPrice: 0,
      salaryCurrency: "",
      originPayrollItems: [],
      salaryItems: [],
      teachingLogs: [],
      paymentMethods: [],
      transactions: [],
      emailToUsers: [],
      PaidAt: null,
      teacherUnpaid: null,
      selectedPaymentMethod: null,
      remark: "",
      problem: "",
      isSomethingWrong: false
    };
  },
  computed: {
    userMathods() {
      return user;
    },
    instant() {
      return instant;
    },
    money() {
      return money;
    },
    pageTitle() {
      return `${this.salaryTitle} ${this.$t("pageTitle.SalaryView")}`;
    },
    problemBlockHeight() {
      return this.isSomethingWrong ? "160px" : "0px";
    },
    salaryItemCurrencys() {
      return this.salaryItems.map(salary => salary.currency);
    },
    salaryMainCurrency() {
      return this.getMainCurrency(this.salaryItemCurrencys);
    },
    isSalaryItemCurrencyMultiple() {
      return this.isCurrencyMultiple(this.salaryItemCurrencys);
    },
    teachingLogCurrencys() {
      return this.getTeachingLogItems().map(
        teachingLog => teachingLog.price_currency
      );
    },
    teachingLogMainCurrency() {
      return this.getMainCurrency(this.teachingLogCurrencys);
    },
    isTeachingLogCurrencyMultiple() {
      return this.isCurrencyMultiple(this.teachingLogCurrencys);
    }
  },
  async created() {
    await this.initialExchangeRate();
    if (this.isRoleAdmin()) {
      await this.$store.dispatch("salary/fetchTeachersUnpaids");
    } else if (this.isRoleTeacher()) {
      await this.$store.dispatch("salary/fetchTeachersPayrolls");
    }
    this.teacherUnpaid = this.$store.getters["salary/unpaid"](
      this.$route.params.teacherId
    );
    await this.fetchPayroll();
    await this.initialSalaryItems();
  },
  methods: {
    isPaid() {
      return this.status === EnumSalaryStatuses.finished;
    },
    isChecked() {
      return this.status === EnumSalaryStatuses.checked;
    },
    isCreated() {
      return this.status === EnumSalaryStatuses.created;
    },
    isProblem() {
      return this.status === EnumSalaryStatuses.problem;
    },
    salaryHasSend() {
      return 0 < (this.emailToUsers || []).length;
    },
    salayIsTemp() {
      return !this.salaryHasSend() && this.isCreated();
    },
    async fetchPayroll() {
      const {
        problems,
        title,
        remark,
        status,
        transactions,
        total_price: totalPrice,
        price_currency: currency,
        payroll_items: payrollItems,
        teaching_logs: teachingLogs,
        teacher_user_payment: teacherPayment,
        payroll_emails: payrollEmails
      } = await salaryApi.fetchPayroll(this.$route.params.salaryId);
      this.problem = problems.length ? problems[0].problem : "";
      this.totalPrice = Number(totalPrice);
      this.salaryCurrency = currency;
      this.salaryTitle = title;
      this.remark = remark;
      this.status = status;
      this.teachingLogs = teachingLogs;
      this.originPayrollItems = payrollItems;
      this.paymentMethods = [teacherPayment || {}];
      this.emailToUsers = payrollEmails.map(payrollEmail => {
        const emailUsers = payrollEmail.emails.map(email => {
          const user = payrollEmail.users.find(user => user.email === email);
          if (user) return user;

          return { email };
        });
        return {
          ...payrollEmail,
          emailUsers
        };
      });
      this.transactions = transactions.map(transaction => ({
        ...transaction,
        displayDate: moment(transaction.datetime).format("YYYY-MM-DD"),
        originalPrice: transaction.original_price,
        originalCurrency: transaction.original_currency
      }));
      if (this.isPaid()) {
        this.PaidAt = this.transactions[0].displayDate;
      }
    },
    async initialSalaryItems() {
      const otherItem = this.originPayrollItems
        .filter(({ payable_type }) => payable_type === "")
        .map(payrollItem => ({
          type: "otherItem",
          statement: payrollItem.title,
          salary: Number(payrollItem.price),
          currency: payrollItem.price_currency
        }));
      this.salaryItems = [
        {
          type: "sessionClass",
          statement: "Private lessons",
          salary: this.getPrivateLessonSalaryTotal(),
          currency: this.teachingLogMainCurrency
        },
        ...otherItem
      ];
    },
    getTeachingLogItems() {
      return this.originPayrollItems.filter(
        ({ payable_type }) => payable_type !== ""
      );
    },
    getPrivateLessonSalaryTotal() {
      const salarys = this.teachingLogs.map(teachingLog => ({
        price: Number(teachingLog.salary) * teachingLog.full_hour,
        currency: teachingLog.salary_currency
      }));
      return this.calculationPriceWithCurrency(salarys);
    },
    getTeachingLogTotal() {
      const salarys = this.getTeachingLogItems().map(teachingLog => ({
        price: Number(teachingLog.price),
        currency: teachingLog.price_currency
      }));
      return this.calculationPriceWithCurrency(salarys);
    },
    getLesssonLogSummaries({ columns, data: teachingLogs }) {
      const sums = [];
      if (teachingLogs.length === 0) return sums;
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 3) columnsText = this.$t("column.total");
        if (index === 4)
          columnsText = this.money.sumUpPrices(
            teachingLogs.map(({ full_hour }) => full_hour)
          );

        if (index === 5) {
          columnsText = this.addCurrencySymbol(
            this.getPrivateLessonSalaryTotal(),
            this.teachingLogMainCurrency
          );
        }

        sums[index] = columnsText;
      });
      return sums;
    },

    getSalaryItemSummaries({ columns, data: salaryItems }) {
      const sums = [];
      if (salaryItems.length === 0) return sums;
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 0) columnsText = this.$t("column.total");
        if (index === 1) {
          columnsText = this.addCurrencySymbol(
            this.totalPrice,
            this.salaryCurrency
          );
        }
        sums[index] = columnsText;
      });
      return sums;
    },
    redirectToSalaryList() {
      this.$router.push({ name: "Salary" });
    },
    async sendMail() {
      try {
        await salaryApi.sendSalaryMail(this.$route.params.salaryId);
        await this.fetchPayroll();
        this.initialSalaryItems();
        this.$message.success("薪資單寄送成功！");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async teacherSubmitReponse() {
      try {
        if (this.isSomethingWrong) {
          await salaryApi.submitPayrollProblem(this.$route.params.salaryId, {
            problem: this.problem,
            items: []
          });
        } else {
          await salaryApi.markCheckoedPayroll(this.$route.params.salaryId);
        }

        this.$message({
          showClose: true,
          message: this.$t("message.update_success"),
          type: "success"
        });
        this.redirectToSalaryList();
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    },
    async updateSalary() {
      try {
        await salaryApi.updatePayroll(this.$route.params.salaryId, {
          title: this.salaryTitle
        });
        this.editTitle = false;
        this.$message({
          showClose: true,
          message: this.$t("message.update_success"),
          type: "success"
        });
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    },
    async createSalaryTransaction(transaction) {
      const postTransaction = {
        original_price: this.totalPrice,
        original_currency: this.salaryCurrency,
        ...transaction,
        datetime: moment(transaction.date).format("YYYY-MM-DD HH:mm:SS")
      };
      try {
        await salaryApi.createSalaryTransaction(
          this.$route.params.salaryId,
          postTransaction
        );
        await salaryApi.markSalaryPaid(this.$route.params.salaryId);
        this.$message({
          showClose: true,
          message: this.$t("message.update_success"),
          type: "success"
        });
        this.redirectToSalaryList();
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    },
    deleteTeacherPayroll(payrollId) {
      return async () => {
        await salaryApi.deleteTeacherPayroll(payrollId);
        this.redirectToSalaryList();
      };
    }
  }
};
</script>

<style scope>
.rowsGap {
  margin: 20px 0px;
}
.table th,
.table td {
  padding: 4px;
}

h2 {
  color: #43a06b;
}

.originalPrice {
  margin-left: 24px;
  color: #999999;
}

.el-table__row:hover .el-steps .is-icon {
  background: rgb(245, 247, 250);
}

.problem {
  overflow: hidden;
  transition: height 0.5s;
}
</style>
